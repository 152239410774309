import React, { Component, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import axios from "axios";

import { BASE_URL  , FRONTEND_P2P_URL} from "../../config/constant";
// import logo from './../../images/logo.png'

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisable: false,
      p2pUrl : FRONTEND_P2P_URL,
      showP2pModal: false
    };
  }

  componentDidMount() {
    let id = localStorage.getItem("userDetails", "");
    console.log("User: ", id);

    if (localStorage.getItem("userDetails")) {
      axios
        .post(BASE_URL + `userInformation/get`, {
          id: id,
        })
        .then((res) => {
          // console.log(res.data.data.uniqueId);
          if (res.data.data && res.data.data.uniqueId && res.data.message === "success") {
            this.setState({
              uniqueId: res.data.data.uniqueId,
            });
          }
        });
    }
  }
handleP2pModal =(show)=>{
  this.setState({showP2pModal: show})
}
  render() {
    return <>
    <P2PModal showP2pModal={this.state.showP2pModal} setShow={this.handleP2pModal}/>
    {
      localStorage.getItem("userDetails") == null ? (
        <header className={this.props.blueBg ? "" : null}>
          <nav className="navbar">
            <div className="container">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                >
                  {" "}
                  <span className="sr-only">Toggle navigation</span>{" "}
                  <span className="icon-bar"></span>{" "}
                  <span className="icon-bar"></span>{" "}
                  <span className="icon-bar"></span>{" "}
                </button>
                <a className="navbar-brand" href="/">
                  <img
                    src={`/images/${
                      this.props.blueBg ? "logo.png" : "logo.png"
                    }`}
                    alt="Company Logo"
                  />
                </a>
              </div>
              <div
                id="navbar"
                className="navbar-collapse collapse"
                aria-expanded="false"
              >
                <ul className="nav navbar-nav navbar-right nav_btn">
                <li className="dropdown">
                    {" "}
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Trade
                      <span className="caret"></span>
                    </a>
                    <ul className="dropdown-menu">
                    <li className="">
                    <a href="/trade/BTC/USDT/SPOT">Spot</a>
                  </li>
                  {/* <li className="">
                    <a href="/margin-trade/BTC/USDT/CROSS">Margin</a>
                  </li> */}
                    </ul>
                  </li>
                  <li className="">
                  {sessionStorage.getItem('p2pTnc') ? <Link to="/p2p">P2P</Link>
                    :<a href="javascript:void" onClick={()=>this.handleP2pModal(true)}>P2P</a>}
                  {/* <Link to="/p2p-coming-soon">P2P</Link> */}
  
                    {/* <a href={this.state.p2pUrl} target={"_blank"}>P2P</a> */}
                  </li>
                  {/* <li>
                    <a href="/news">News</a>
                  </li> */}
                  <li>
                    <a href="/login">
                      Log In
                    </a>
                  </li>
                  <li>
                    <a href="/register" className="btn stroke_btn">
                      Sign Up
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      ) : (
        <header>
          <nav className="navbar">
            <div className="container-fluid">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                >
                  {" "}
                  <span className="sr-only">Toggle navigation</span>{" "}
                  <span className="icon-bar"></span>{" "}
                  <span className="icon-bar"></span>{" "}
                  <span className="icon-bar"></span>{" "}
                </button>
                <a className="navbar-brand" href="/">
                  <img src="/images/logo.png" alt="Company Logo" />
                </a>{" "}
              </div>
              <div
                id="navbar"
                className="navbar-collapse collapse"
                aria-expanded="false"
                style={{ height: "1px" }}
              >
                
                <ul className="nav navbar-nav navbar-right nav_btn">
                  {/* <li >
                    <Link to="/buyecna"> <span><img src={`/images/mini-logo.png`} alt='' style={{height:20, width:20}}/></span> Buy Ecanna</Link>
                  </li> */}
              
                  <li className="dropdown">
                    {" "}
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Trade
                      <span className="caret"></span>
                    </a>
                    <ul className="dropdown-menu">
                    <li className="">
                    <a href="/trade/BTC/USDT/SPOT">Spot</a>
                  </li>
                  {/* <li className="">
                    <a href="/margin-trade/BTC/USDT/CROSS">Margin</a>
                  </li> */}
                    </ul>
                  </li>
                  <li className="">
                 {sessionStorage.getItem('p2pTnc') ? <Link to="/p2p">P2P</Link>
                    :<a href="javascript:void" onClick={()=>this.handleP2pModal(true)}>P2P</a>}
                  {/* <Link to="/p2p-coming-soon">P2P</Link> */}
  
                  </li>
                   
                  <li>
                    <a href="/dashboard">Funds</a>
                  </li>
                  <li>
                    {/* <a href="/dashboardMargin">Margin</a> */}
                  </li>
                  <li className="dropdown">
                    {" "}
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-user"></i>{" "}
                      {localStorage.getItem("userName")}{" "}
                      <span className="caret"></span>
                    </a>
                    <ul className="dropdown-menu">
                      {/* <li><a href="#">User ID : {this.state.uniqueId}</a></li> */}
                      <li>
                        <a href="/dashboard">Dashboard</a>
                      </li>
                      <li>
                    {/* <a href="/dashboardMargin">Margin </a> */}

                  </li>
                      <li>
                        <a href="/referral">Referral</a>
                      </li>
                      {/* <li>
                        <a href="#">Security</a>
                      </li>
                      <li>
                        <a href="#">API</a>
                      </li> */}
  
                      {/* <li>
                        <a href="#">Add Nominee</a>
                      </li>
                      <li>
                        <a href="#">About  E-Canna</a>
                      </li> */}
                      <li>
                        <a href="/logout">Logout</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      )}
    </>
    
    
  }
}



const P2PModal = ({showP2pModal, setShow})=>{
// const [show, setShow] = useState(showP2pModal);
const [tnc, setTnc] = useState(false);
const history = useHistory()
const handleClose=()=>{
  setShow(false)
}
const onAgree =()=>{
  if(tnc){
    sessionStorage.setItem('p2ptnc', true)
    history.push('/p2p')

  }
}
  return(
    <div
    className={`modal fade in popup_form ${showP2pModal && "show"}`}
    id="transfer_modal"
    data-backdrop="false"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content rounded-md">
        <div className="d-flex flex-between px-2 py-2">
          <h5 className="modal-title" id="exampleModalLabel">
            Warning
          </h5>

          <button
            type="button"
            className="close font-16"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              handleClose();
            }}
          >
            {" "}
            <span aria-hidden="true">&times;</span>{" "}
          </button>
        </div>
        <div className="modal-body">
              <p>
              To avoid becoming a victim of scammers, NEVER transfer cryptocurrency before actually receiving the payment! Don't believe anyone who claims to be a customer support and convinces you to complete the transaction before you receive the payment - they're scammers. Once the seller confirms the order and transfers the assets to the buyer, the transaction is considered completed and cannot be disputed.
               ecannacoin.com does not take any responsibility for transactions made outside of the platform.
              </p>
            <div className="mt-3">
                <input className="mr-3" type="checkbox" checked={tnc} onChange={(e)=> setTnc(e.target.checked)}/> &nbsp;I have read and agree to the above content
            </div>

            <div style={{display: 'flex', justifyContent: 'end'}}>
                <button disabled={!tnc} className="btn btn-info" onClick={()=>onAgree()}>Agree</button>
                <button style={{marginLeft: 3}} className="btn btn-warning" onClick={()=> handleClose()}>Close</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Header;
