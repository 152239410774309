import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
// import axios from 'axios';
import axios from './httpInterceptor';
import { BASE_URL } from "../../config/constant";
import Notifications, { notify } from 'react-notify-toast';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import BigNumber from 'bignumber.js'
import { PieChart } from '../../Components/pieChart/pieChart';
import { TransferModal } from '../../Components/margin-dashboard/TransferBalance';
import { BorrowModal } from '../../Components/borrow/borrow';
import { RepayModal } from '../../Components/repay/repay';
import Loader from '../../React-Loader/loader';
import moment from 'moment';

var id;
var tableFields = [];
var fessAmt = 0;
var fessUsdValue = 0;
class OrderHistoryMargin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            historyOrderrows: [],
            loading: false

        };
    }
    tabsArray = [
        {
            title: 'All',
            id: 'tab_all',
            statusText: 'all'
        },
        {
            title: 'Filled Orders',
            id: 'tab_filled_orders',
            statusText: 'close'
        },
        {
            title: 'Open Orders',
            id: 'tab_open_orders',
            statusText: 'open'
        },
        {
            title: 'Cancelled Orders',
            id: 'tab_cancelled_orders',
            statusText: 'cancelled'
        }
    ]
    async componentDidMount() {
        if (!localStorage.getItem('userDetails')) {
            this.props.history.push('/login')
        } else {
            this.setState({ userId: localStorage.getItem('userDetails') })

        }

    }

    async componentWillMount() {
        id = localStorage.getItem('userDetails');
        console.log("User id: ", id);

        this.getOrders('all')
    }



    getOrders = async (orderStatus) => {
        this.setState({ loading: true })

        id = localStorage.getItem('userDetails');
        await axios.get(BASE_URL + `marginOrder/get/${id}/${orderStatus}`)
            .then( (res) => {
                this.setState({ historyOrderrows: res.data.data, loading: false })
                console.log(this.state)
            }).catch((err)=>{
                this.setState({ historyOrderrows: [], loading: false })
            })
    }

    render() {
        document.title = "Margin Orders";
        document.body.classList.add('faded_bg');
        return (
            <div>
                <Notifications />
                <Header loadFees={this.state.loadFeeSubs} />
                {this.state.loading ? <Loader /> : <></>}

                <section id="middel_area" className='mDashboard'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 area_left">

                                <Sidebar loadFees={this.state.loadFeeSubs} />
                            </div>

                            <div className="col-md-10 col-sm-9 area_right">
                                <div className="clearfix assets_part">
                                    <div className="dashboard_top_info row">

                                    </div>
                                </div>



                                <div className="white_bx clearfix">
                                    <div className="side_txt">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h6> Margin Orders
                                                    {/* <Link to={'/borrowHistory'} className='btn btn-primary btn-sm mt_mb_0'>
                                                   Borrow History
                                                   </Link>  */}
                                                </h6>
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <ul className="nav nav-tabs" role="tablist">
                                            {this.tabsArray.map((tab, index) => (
                                                <li className={index === 0 && "active"}>
                                                    <a href={`#${tab.id}`} role="tab" data-toggle="tab" onClick={() => this.getOrders(tab.statusText)}>
                                                        {tab.title}
                                                    </a>
                                                </li>))}
                                        </ul>
                                        <div className="tab-content order_table">
                                            <div
                                                role="tabpanel"
                                                className="tab-pane active"
                                                id="portfolio"
                                            >
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Amount</th>
                                                                <th>Fees</th>
                                                                <th>Total</th>
                                                                <th>Side</th>
                                                                <th>Date</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.historyOrderrows.map((row, xid) => (
                                                                <tr key={xid}>
                                                                    <td className={row.orderType == "Long" ? "green_clr" : "red_clr"}>
                                                                        {row.coinName +"-"+ row.pair}
                                                                    </td>
                                                                    <td className={row.orderType == "Long" ? "green_clr" : "red_clr"}>{Number(row.status !== 'close' ? row.entryPrice : row.exitPrice).toFixed(2)} {row.pair}</td>
                                                                    <td>{row.amount} {row.coinName}</td>
                                                                    <td>{row.tradingFee * (row.status !== 'close' ? row.entryPrice : row.exitPrice) * row.amount /100 } USDT</td>
                                                                    <td>{row.total} {row.pair}</td>
                                                                    <td className={row.orderType == "Long" ? "green_clr" : "red_clr"}>{row.orderType}</td>
                                                                    <td>{moment(row.createdAt).format('DD MMM yyyy HH:MM:SS A')}</td>
                                                                    <td style={{textTransform:"capitalize"}}>{row.status}</td>
                                                                </tr>
                                                            )).reverse()}
                                                        </tbody>
                                                    </table>
                                                    {this.state.historyOrderrows.length == 0 &&
                                                        <div className='text-danger text-center' style={{padding: 48}} > No record to display!  </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default OrderHistoryMargin;