require('dotenv').config()
console.log(process.env)
 
export const BASE_URL =  'https://api.ecannacoin.com/'      // Production
// export const BASE_URL =  'https://testapi.ecannacoin.com/'      // Testing 
// export const BASE_URL = 'http://localhost:2087/'

// export const WEB_SOCKET_URL = 'ws://localhost:2087'
// export const WEB_SOCKET_URL = 'ws://testapi.ecannacoin.com' //Test server
export const WEB_SOCKET_URL = 'wss://api.ecannacoin.com'    // Prod server
// 

export const IMG_UPLOAD_URL =  'https://api.ecannacoin.com/'


export const FRONTEND_URL = "https://ecannacoin.com/"
export const FRONTEND_P2P_URL = "https://ecannacoin.com/p2p"
export const IMAGE_START_URL = process.env.NODE_ENV === 'development' ? "http://127.0.0.1:8080" : "https://kyc.ecannacoin.com";

export const hostName =  "chat.ecannacoin.com" //"52.70.79.109"

//dev
const TEST_SITE_KEY_DEv =  process.env.NODE_ENV === 'development' ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI": "6LfgEyciAAAAAJp2Q3jhwp1FBwKj_VIcoIIo29Ra"; 
const TEST_SITE_KEY_Prod =  process.env.NODE_ENV === 'development' ? "6LeoNTMeAAAAAFlAuhzcszsm1Ma4HvYmieI6RJme" : "6LfgEyciAAAAAJp2Q3jhwp1FBwKj_VIcoIIo29Ra"; 


//live
// const TEST_SITE_KEY_Prod=  "6LfgEyciAAAAAJp2Q3jhwp1FBwKj_VIcoIIo29Ra"; 
// const TEST_SITE_KEY_DEv = "6LfgEyciAAAAAJp2Q3jhwp1FBwKj_VIcoIIo29Ra"; 



export const GOOGLE_CAPTCHA_KEY = process.env.NODE_ENV === 'development'? TEST_SITE_KEY_DEv:TEST_SITE_KEY_Prod

export const ECANNA_TOKEN_SYMBOL = 'ECNA'

export const HYPERVERGE_indianWorkflow = "workflow_w4Sbr4D"; 
export const HYPERVERGE_nonIndianWorkflow = "workflow_0B19dwA"

